// 透過 @ay-nestjs/share 產生
/* eslint-disable */

export class FetchEventListItem {
  public eventId!: string;
  public name!: string;
  public datetime!: Date;
  public updatedAt!: Date;
  public folderId!: string;
  public backgrounds!: { url: string; name: string }[];
  public moduleTypes!: string[];

  public static fromJSON(json: any): FetchEventListItem {
    const dto = new FetchEventListItem();
    dto.eventId = json.eventId;
    dto.name = json.name;
    dto.datetime = new Date(json.datetime);
    dto.updatedAt = new Date(json.updatedAt);
    dto.folderId = json.folderId;
    dto.backgrounds = json.backgrounds;
    dto.moduleTypes = json.moduleTypes;
    return dto;
  }
}
// a2dd00638c790158261891c148c063501a833e82f1b6e0b84cdf92d04fe6eb1a

// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';

export class SessionModel {
  static fetch(sessionId: string): Promise<{
    sessionId: string;
    sessionName: string;
  }> {
    return wsc.execute('/ws/session/fetch', sessionId) as any;
  }

  static rename(sessionId: string, name: string): Promise<any> {
    return wsc.execute('/ws/session/rename', sessionId, name) as any;
  }

  static delete(sessionId: string): Promise<any> {
    return wsc.execute('/ws/session/delete', sessionId) as any;
  }
}
// b395338f84983416a87eaa95cdb84cb847a1dfd48303d2606834bafe9e06c915

// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { Observable } from 'rxjs';

export class SpinnerModel {
  static listenIsWinner(): Observable<boolean> {
    return wsc.subscribe('/ws/spinner/listenIsWinner') as any;
  }

  static listenIsFinished(): Observable<boolean> {
    return wsc.subscribe('/ws/spinner/listenIsFinished') as any;
  }

  static fetchIsWinner(): Promise<boolean> {
    return wsc.execute('/ws/spinner/fetchIsWinner') as any;
  }

  static fetchIsFinished(): Promise<boolean> {
    return wsc.execute('/ws/spinner/fetchIsFinished') as any;
  }

  static setWinner(winnerIdentifiers: string[]): Promise<void> {
    return wsc.execute('/ws/spinner/setWinner', winnerIdentifiers) as any;
  }

  static setIsSpinExecuted(): Promise<void> {
    return wsc.execute('/ws/spinner/setIsSpinExecuted') as any;
  }

  static isSpinExecuted(): Observable<boolean> {
    return wsc.subscribe('/ws/spinner/isSpinExecuted') as any;
  }

  static fetchIsFilmstripExecuted(): Promise<boolean> {
    return wsc.execute('/ws/spinner/fetchIsFilmstripExecuted') as any;
  }
}
// b66f8fa7095f3dffa49993165b134ed03391ef1b07c8ad3bdac2accf3fdb928f

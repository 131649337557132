// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';

export class RoleModel {
  static fetchList(): Promise<
    {
      roleId: number;
      roleName: string;
      isAdmin: boolean;
      hasAuthority: boolean;
    }[]
  > {
    return wsc.execute('/ws/role/fetchList') as any;
  }

  static changeExistAccountRole(option: {
    accountId: number;
    roleId: number;
  }): Promise<void> {
    return wsc.execute('/ws/role/changeExistAccountRole', option) as any;
  }

  static changeInviteAccountRole(option: {
    accountInviteId: number;
    roleId: number;
  }): Promise<void> {
    return wsc.execute('/ws/role/changeInviteAccountRole', option) as any;
  }

  static fetchAuthorityList(): Promise<
    {
      category: string;
      authorities: {
        name: string;
        key: string;
      }[];
    }[]
  > {
    return wsc.execute('/ws/role/fetchAuthorityList') as any;
  }

  static fetchAllAuthorityKeys(): Promise<string[]> {
    return wsc.execute('/ws/role/fetchAllAuthorityKeys') as any;
  }

  static fetchRoleAuthoritiesMap(): Promise<{ [roleId: number]: string[] }> {
    return wsc.execute('/ws/role/fetchRoleAuthoritiesMap') as any;
  }

  static updateRoleAuthorities(
    roleId: number,
    authorities: string[],
  ): Promise<void> {
    return wsc.execute(
      '/ws/role/updateRoleAuthorities',
      roleId,
      authorities,
    ) as any;
  }

  static fetchRoleAuthorities(): Promise<string[]> {
    return wsc.execute('/ws/role/fetchRoleAuthorities') as any;
  }

  static fetchRole(): Promise<{
    roleId: number;
    roleName: string;
    isAdmin: boolean;
    hasAuthority: boolean;
  }> {
    return wsc.execute('/ws/role/fetchRole') as any;
  }
}
// 5737f0fdd4f3d8fa66ae8b9b741e7d8116114046bf9ddefcdfa7f99ce76fe93e

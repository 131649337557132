// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { EventLineLoginDto } from './dto/event-line-login.dto';

export class EventLineLoginModel {
  static fetchLineLogin(lineLoginId: string): Promise<any> {
    return wsc.execute(
      '/ws/event-line-login/fetchLineLogin',
      lineLoginId,
    ) as any;
  }

  static fetchLineLoginList(): Promise<any> {
    return wsc.execute('/ws/event-line-login/fetchLineLoginList') as any;
  }

  static createLineLogin(lineLoginDto: EventLineLoginDto): Promise<any> {
    return wsc.execute(
      '/ws/event-line-login/createLineLogin',
      lineLoginDto,
    ) as any;
  }

  static updateLineLogin(lineLoginDto: EventLineLoginDto): Promise<any> {
    return wsc.execute(
      '/ws/event-line-login/updateLineLogin',
      lineLoginDto,
    ) as any;
  }

  static deleteLineLogin(lineLoginId: string): Promise<any> {
    return wsc.execute(
      '/ws/event-line-login/deleteLineLogin',
      lineLoginId,
    ) as any;
  }

  static ensureLineLogin(
    channelId: string,
    channelSecret: string,
  ): Promise<any> {
    return wsc.execute(
      '/ws/event-line-login/ensureLineLogin',
      channelId,
      channelSecret,
    ) as any;
  }

  static ensureLineLoginCallbackUrl(
    channelId: string,
    callbackUrl: string,
  ): Promise<any> {
    return wsc.execute(
      '/ws/event-line-login/ensureLineLoginCallbackUrl',
      channelId,
      callbackUrl,
    ) as any;
  }
}
// fa845d512d1c72b4fce2105f4ba4dd0f580cf743a671e353868b508328d487ec

import { NextObserver } from 'rxjs';
import { Job } from '../util/job';
import { DownloadResponse } from './response';

export class DownloadJob extends Job {
  public arrayBuffers: ArrayBuffer[] = [];
  public fileName: string = '';
  public fileSize: number = 0;
  public downloadedSize: number = 0;
  public fileType: string = '';

  public constructor(
    public override path: string,
    public override args: any[],
    public observer: NextObserver<DownloadResponse>,
  ) {
    super(path, args);
  }
}

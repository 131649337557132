import { NextObserver, Observable, shareReplay } from 'rxjs';
import { Behavior } from '../util/behavior';
import { Queue } from '../util/queue';
import { DownloadJob } from './job';
import { DownloadResponse } from './response';

type DownloadStatus =
  | 'processing'
  | 'start_download'
  | 'downloading'
  | 'done'
  | 'error';

export class DownloadBehavior extends Behavior {
  private _queue = new Queue<DownloadJob>();

  public afterConnectedServer() {
    this._queue.run(this._downloadFromServer.bind(this));
  }

  public download(
    method: string,
    ...args: any[]
  ): Observable<DownloadResponse> {
    return new Observable((observer: NextObserver<DownloadResponse>) => {
      const job = new DownloadJob(method, args, observer);
      this._queue.add(job);

      if (this.client.isReady()) {
        this.client.checkQueryLimit(job, this._downloadFromServer.bind(this));
      }
    }).pipe(shareReplay(1));
  }

  private _downloadFromServer(job: DownloadJob) {
    const { path, jobId, args, observer } = job;
    const key = `${path}/${jobId}`;

    this.client.io.off(`${key}/start_download`);
    this.client.io.off(`${key}/downloading`);
    this.client.io.off(`${key}/done`);

    this.client.io.on(
      `${key}/start_download`,
      (
        status: DownloadStatus,
        fileInfo: { size: number; name: string; type: string },
      ) => {
        if (observer.closed) {
          this.client.io.emit(`download_cancel`, jobId);
          return;
        }

        job.fileSize = fileInfo.size;
        job.fileName = fileInfo.name;
        job.fileType = fileInfo.type;
        job.downloadedSize = 0;

        job.observer.next({ status: 'start_download', fileInfo });
        this.client.io.emit('download_next', path, jobId);
      },
    );

    this.client.io.on(`${key}/downloading`, (arrayBuffer: ArrayBuffer) => {
      console.log(`${key}/downloading`);
      if (observer.closed) {
        this.client.io.emit(`download_cancel`, jobId);
        return;
      }

      job.downloadedSize += arrayBuffer.byteLength;
      job.arrayBuffers.push(arrayBuffer);
      job.observer.next({
        status: 'downloading',
        percentage: (job.downloadedSize / job.fileSize) * 100,
        downloadedSize: job.downloadedSize,
        fileSize: job.fileSize,
      });

      if (job.downloadedSize >= job.fileSize) {
        const buffer = concatArrayBuffers(job.arrayBuffers);
        const { fileName, fileType } = job;
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const file = new File([blob], fileName, { type: fileType });
        job.observer.next({ status: 'done', file });
        job.observer.complete?.();

        this.client.io.off(`${key}/start_download`);
        this.client.io.off(`${key}/downloading`);
        this.client.io.off(`${key}/done`);
      }

      this.client.io.emit('download_next', path, jobId);
    });

    this.client.io.emit('download', path, args, jobId);
  }
}

function concatArrayBuffers(buffers: ArrayBuffer[]) {
  let length = 0;
  for (const buffer of buffers) {
    length += buffer.byteLength;
  }
  const result = new ArrayBuffer(length);
  const resultView = new Uint8Array(result);
  let offset = 0;
  for (const buffer of buffers) {
    const bufferView = new Uint8Array(buffer);
    resultView.set(bufferView, offset);
    offset += buffer.byteLength;
  }
  return result;
}

import { randomInt } from './rand';

export function createRandomString(chars: string, length: number) {
  let string = '';

  for (let i = 0; i < length; i++) {
    const index = randomInt(chars.length);
    string += chars[index];
  }

  return string;
}

export function downloadFile(file: File): void {
  const url = window.URL.createObjectURL(file!);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = file?.name ?? '未命名檔案';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}

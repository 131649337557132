// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { ModuleJson } from '../../../../../modules';
import { wsc } from '../wsc';

export class DevModel {
  static login(params: {
    eventId: string;
    eventSessionId: number;
  }): Promise<any> {
    return wsc.execute('/ws/dev/login', params) as any;
  }

  static saveDev(params: {
    eventId: string;
    modules: ModuleJson[];
    sessionId: number;
  }): Promise<any> {
    return wsc.execute('/ws/dev/saveDev', params) as any;
  }
}
// c3a31a69d40096b5a84fba4d7c20b84026012d3916375aa9cd769dc9ad920a2a

// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { UploadResponse } from '@ay-nestjs/share-client';
import { Observable } from 'rxjs';

export class ParticipantModel {
  static refresh(token: string): Promise<string> {
    return wsc.execute('/ws/participant/refresh', token) as any;
  }

  static uploadAvatar(file: File): Observable<UploadResponse<{ url: string }>> {
    return wsc.upload('/ws/participant/uploadAvatar', file) as any;
  }
}
// e7680e8050b401e0894edfa64d97d010b355b37d34404be2c6d82562e87bbf29

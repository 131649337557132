// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { Observable } from 'rxjs';

export class SurveyModel {
  static listenCount(): Observable<{ options: number[]; total: number }[]> {
    return wsc.subscribe('/ws/survey/listenCount') as any;
  }

  static fetchCurrentQuestionIndex(): Promise<number> {
    return wsc.execute('/ws/survey/fetchCurrentQuestionIndex') as any;
  }

  static answer(questionIndex: number, optionIndex: number): Promise<void> {
    return wsc.execute('/ws/survey/answer', questionIndex, optionIndex) as any;
  }
}
// aeb63bebca9f8651c799a00688e221b54d931c33d0b399f4c5deee2211a2329a

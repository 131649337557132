// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';

export class OAuthFbLoginModel {
  /** 
   * @req {
    "accessToken": "EAAP2ZAZArEJTEBO4j9JkNAnrxgRMgPPYmVH0RKeZAdGiILaDKVelJEt5EPjNpDN9ib0QiCRtEWCSQfZCksLnnNIji91RDhg7k6EEcnZBWZB3coWdtgOX04sIoooXNsWs19ailZBEGZAnFYNcJZAhq0ZBHFbsQBrMaSjfi9nyKELcXLDCPQhjYvcTtRx7ZAr5VG0I2TlZBzfmlFdC5kMDoGaGzQZDZD",
  } */
  static start(
    eventId: string,
    name: string,
    picture: string,
    accessToken: string,
  ): Promise<any> {
    return wsc.execute(
      '/ws/o-auth-fb-login/start',
      eventId,
      name,
      picture,
      accessToken,
    ) as any;
  }
}
// cb5b741845ecc1ba237a1017e6d3e279440cc871230fbb2102e90bea66d0b7c7

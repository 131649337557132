// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { MobileEventFetchResponseDto } from './dto/fetch-response.dto';

export class MobileEventModel {
  static fetch(eventId: string): Promise<MobileEventFetchResponseDto> {
    return wsc.execute('/ws/mobile-event/fetch', eventId) as any;
  }

  static login(param: {
    eventId: string;
    fields: { [key: string]: string };
  }): Promise<string> {
    return wsc.execute('/ws/mobile-event/login', param) as any;
  }
}
// ded5e6ffad92acefae19e337d17c9adc8acceb0368ef25a17191b1e79ff135a5

// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { CreateFolderDto } from './dto/create-folder.dto';
import { FetchFolderListItemDto } from './dto/fetch-folder-list-item.dto';
import { FetchFolderDto } from './dto/fetch-folder.dto';

export class FolderModel {
  static fetch(folderId: string): Promise<FetchFolderDto> {
    return wsc.execute('/ws/folder/fetch', folderId) as any;
  }

  static fetchList(): Promise<FetchFolderListItemDto[]> {
    return wsc.execute('/ws/folder/fetchList') as any;
  }

  static create(dto: CreateFolderDto): Promise<{ folderId: string }> {
    return wsc.execute('/ws/folder/create', dto) as any;
  }

  static rename(folderId: string, name: string): Promise<void> {
    return wsc.execute('/ws/folder/rename', folderId, name) as any;
  }

  static remove(folderId: string): Promise<void> {
    return wsc.execute('/ws/folder/remove', folderId) as any;
  }
}
// 042f2de0152f997c11214bf83829728bef8c6d54d6905c3e7dcd76b1b613babd

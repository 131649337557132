// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { Observable } from 'rxjs';
import { AccountListItemDto } from './dto/account-list-item.dto';
import { CompanyOverviewDto } from './dto/company-overview.dto';
import { FetchLineUserInfoDto } from './dto/fetch-line-user-info.dto';
import { GosuLoginConfigDto } from './dto/gosu-login-config.dto';
import { LineLoginDto } from './dto/line-login.dto';

export class GosuLoginModel {
  static register(
    name: string,
    account: string,
    password: string,
  ): Promise<void> {
    return wsc.execute(
      '/ws/gosu-login/register',
      name,
      account,
      password,
    ) as any;
  }

  static login(account: string, password: string): Promise<string> {
    return wsc.execute('/ws/gosu-login/login', account, password) as any;
  }

  static refresh(accessToken: string): Promise<string> {
    return wsc.execute('/ws/gosu-login/refresh', accessToken) as any;
  }

  static changePassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<void> {
    return wsc.execute(
      '/ws/gosu-login/changePassword',
      oldPassword,
      newPassword,
    ) as any;
  }

  static updateName(name: string): Promise<boolean> {
    return wsc.execute('/ws/gosu-login/updateName', name) as any;
  }

  // #region Verify Email
  static sendVerifyMail(): Promise<void> {
    return wsc.execute('/ws/gosu-login/sendVerifyMail') as any;
  }

  // #endregion
  // #region SMS Verify
  static sendVerifySms(
    countryCode: string,
    phone: string,
  ): Promise<{ remainingCount: number }> {
    return wsc.execute(
      '/ws/gosu-login/sendVerifySms',
      countryCode,
      phone,
    ) as any;
  }

  static verifySmsCode(
    countryCode: string,
    phone: string,
    code: string,
  ): Promise<string> {
    return wsc.execute(
      '/ws/gosu-login/verifySmsCode',
      countryCode,
      phone,
      code,
    ) as any;
  }

  // #endregion
  // #region reset password
  static sendResetPasswordMail(email: string): Promise<void> {
    return wsc.execute('/ws/gosu-login/sendResetPasswordMail', email) as any;
  }

  static verifyResetPasswordToken(token: string): Promise<boolean> {
    return wsc.execute('/ws/gosu-login/verifyResetPasswordToken', token) as any;
  }

  static changePasswordByToken(
    token: string,
    newPassword: string,
  ): Promise<void> {
    return wsc.execute(
      '/ws/gosu-login/changePasswordByToken',
      token,
      newPassword,
    ) as any;
  }

  // #endregion
  // #region company
  static createCompany(name: string): Promise<string> {
    return wsc.execute('/ws/gosu-login/createCompany', name) as any;
  }

  static fetchCompanyList(): Promise<CompanyOverviewDto[]> {
    return wsc.execute('/ws/gosu-login/fetchCompanyList') as any;
  }

  static selectCompany(companyId: number): Promise<string> {
    return wsc.execute('/ws/gosu-login/selectCompany', companyId) as any;
  }

  // #endregion
  // #region Facebook
  static registerViaFb(fbAccessToken: string, name: string): Promise<string> {
    return wsc.execute(
      '/ws/gosu-login/registerViaFb',
      fbAccessToken,
      name,
    ) as any;
  }

  static loginViaFb(fbAccessToken: string): Promise<string> {
    return wsc.execute('/ws/gosu-login/loginViaFb', fbAccessToken) as any;
  }

  static bindFb(fbAccessToken: string): Promise<void> {
    return wsc.execute('/ws/gosu-login/bindFb', fbAccessToken) as any;
  }

  static unbindFb(): Promise<void> {
    return wsc.execute('/ws/gosu-login/unbindFb') as any;
  }

  // #endregion
  // #region Google
  static registerViaGoogle(googleAccessToken: string): Promise<string> {
    return wsc.execute(
      '/ws/gosu-login/registerViaGoogle',
      googleAccessToken,
    ) as any;
  }

  static loginViaGoogle(googleAccessToken: string): Promise<string> {
    return wsc.execute(
      '/ws/gosu-login/loginViaGoogle',
      googleAccessToken,
    ) as any;
  }

  static bindGoogle(googleAccessToken: string): Promise<any> {
    return wsc.execute('/ws/gosu-login/bindGoogle', googleAccessToken) as any;
  }

  static unbindGoogle(): Promise<any> {
    return wsc.execute('/ws/gosu-login/unbindGoogle') as any;
  }

  // #endregion
  // #region LINE login
  static lineLogin(): Observable<LineLoginDto> {
    return wsc.subscribe('/ws/gosu-login/lineLogin') as any;
  }

  static registerViaLine(): Promise<any> {
    return wsc.execute('/ws/gosu-login/registerViaLine') as any;
  }

  static loginViaLine(): Promise<any> {
    return wsc.execute('/ws/gosu-login/loginViaLine') as any;
  }

  static lineUserInfo(): Promise<FetchLineUserInfoDto> {
    return wsc.execute('/ws/gosu-login/lineUserInfo') as any;
  }

  static bindLine(): Promise<any> {
    return wsc.execute('/ws/gosu-login/bindLine') as any;
  }

  static unbindLine(): Promise<any> {
    return wsc.execute('/ws/gosu-login/unbindLine') as any;
  }

  // #endregion
  static fetchConfig(): Promise<GosuLoginConfigDto> {
    return wsc.execute('/ws/gosu-login/fetchConfig') as any;
  }

  static fetchPasswordDefaultRules(): Promise<{ type: string; args: any }[]> {
    return wsc.execute('/ws/gosu-login/fetchPasswordDefaultRules') as any;
  }

  static updateCompanyName(name: string): Promise<any> {
    return wsc.execute('/ws/gosu-login/updateCompanyName', name) as any;
  }

  static fetchCompanyAccountList(): Promise<AccountListItemDto[]> {
    return wsc.execute('/ws/gosu-login/fetchCompanyAccountList') as any;
  }

  static inviteAccount(
    inviteesAccount: string,
    roleId: number,
  ): Promise<number> {
    return wsc.execute(
      '/ws/gosu-login/inviteAccount',
      inviteesAccount,
      roleId,
    ) as any;
  }

  static fetchFeatureUseable(): Promise<number> {
    return wsc.execute('/ws/gosu-login/fetchFeatureUseable') as any;
  }

  static verifyInviteToken(token: string): Promise<{ email: string }> {
    return wsc.execute('/ws/gosu-login/verifyInviteToken', token) as any;
  }

  static changeAccountInviteRole(
    accountInviteId: number,
    roleId: number,
  ): Promise<void> {
    return wsc.execute(
      '/ws/gosu-login/changeAccountInviteRole',
      accountInviteId,
      roleId,
    ) as any;
  }

  static registerViaInvite(
    token: string,
    name: string,
    password: string,
  ): Promise<{ accessToken: string }> {
    return wsc.execute(
      '/ws/gosu-login/registerViaInvite',
      token,
      name,
      password,
    ) as any;
  }

  static isLoggedIn(): Promise<boolean> {
    return wsc.execute('/ws/gosu-login/isLoggedIn') as any;
  }

  static logout(): Promise<any> {
    return wsc.execute('/ws/gosu-login/logout') as any;
  }

  static removeInviteAccount(accountInviteId: number): Promise<void> {
    return wsc.execute(
      '/ws/gosu-login/removeInviteAccount',
      accountInviteId,
    ) as any;
  }

  static removeExistAccount(accountId: number): Promise<void> {
    return wsc.execute('/ws/gosu-login/removeExistAccount', accountId) as any;
  }
}
// 191f720f0d21fdcebaf4a7edd4297850bbb9bd9849308d35233c9a7cfcab6437

// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { ModuleJson } from '@ay-gosu-party/modules';
import { UploadResponse } from '@ay-nestjs/share-client';
import { Observable } from 'rxjs';
import { CreateEventDto } from './dto/create-event.dto';
import { FetchEventListItem } from './dto/fetch-event-list-item.dto';
import { FetchEventResponseDto } from './dto/fetch-event-response.dto';
import { UpdateEventBasicInfoDto } from './dto/update-event-basic-info.dto';

export class EventModel {
  static fetchList(filter: {
    folderId?: string;
  }): Promise<FetchEventListItem[]> {
    return wsc.execute('/ws/event/fetchList', filter) as any;
  }

  static create(dto: CreateEventDto): Promise<{ eventId: string }> {
    return wsc.execute('/ws/event/create', dto) as any;
  }

  static updateBasicInfo(
    eventId: string,
    dto: UpdateEventBasicInfoDto,
  ): Promise<any> {
    return wsc.execute('/ws/event/updateBasicInfo', eventId, dto) as any;
  }

  static move(eventIds: string[], folderId: string): Promise<void> {
    return wsc.execute('/ws/event/move', eventIds, folderId) as any;
  }

  static fetch(eventId: string): Promise<FetchEventResponseDto> {
    return wsc.execute('/ws/event/fetch', eventId) as any;
  }

  static save(eventId: string, modules: ModuleJson[]): Promise<any> {
    return wsc.execute('/ws/event/save', eventId, modules) as any;
  }

  static rename(eventId: string, name: string): Promise<void> {
    return wsc.execute('/ws/event/rename', eventId, name) as any;
  }

  static remove(eventId: string): Promise<void> {
    return wsc.execute('/ws/event/remove', eventId) as any;
  }

  static uploadAvatar(
    eventId: string,
    file: File,
  ): Observable<UploadResponse<{ id: number; url: string }>> {
    return wsc.upload('/ws/event/uploadAvatar', eventId, file) as any;
  }

  static removeAvatar(eventId: string, avatarId: number): Promise<any> {
    return wsc.execute('/ws/event/removeAvatar', eventId, avatarId) as any;
  }

  static updateAppointedList(
    eventId: string,
    file: File,
  ): Observable<UploadResponse<any>> {
    return wsc.upload('/ws/event/updateAppointedList', eventId, file) as any;
  }

  static checkAppointedList(file: File): Observable<UploadResponse<any>> {
    return wsc.upload('/ws/event/checkAppointedList', file) as any;
  }
}
// 93aa05576c7ae3905415a22a9fbb45885a9caf0756a9ec478212d00d8dff1c81

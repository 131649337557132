// 透過 @ay-nestjs/share 產生
/* eslint-disable */

export class FetchFolderListItemDto {
  public folderId!: string;
  public name!: string;
  public eventCount!: number;
  public updatedAt!: Date;

  public static fromJSON(json: any) {
    const dto = new FetchFolderListItemDto();
    dto.folderId = json.folderId!;
    dto.name = json.name!;
    dto.eventCount = json.eventCount!;
    dto.updatedAt = new Date(json.updatedAt!);
    return dto;
  }
}
// 2efe1a12e27dc9571cca5959f43b5e32173f3a4c32f292568862870922384fd2

// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { UploadResponse } from '@ay-nestjs/share-client';
import { Observable } from 'rxjs';

export class AccountModel {
  static uploadBackground(file: File): Observable<UploadResponse<string>> {
    return wsc.upload('/ws/account/uploadBackground', file) as any;
  }

  static uploadImage(file: File): Observable<UploadResponse<string>> {
    return wsc.upload('/ws/account/uploadImage', file) as any;
  }
}
// ade45cbcfcc7c0137e8907c42bcd0165c42907330a896a748261d7b649a957a3
